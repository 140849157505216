* {
	padding: 0px;
	margin: 0px;
	box-sizing: border-box;
	font-family: 'Roboto', sans-serif;
	-webkit-print-color-adjust: exact;
}

html,
body {
	width: 100%;
	height: 100%;
}

::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #e9e9e9;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: rgb(206, 205, 205);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #aaa;
}

div.content-session a:hover{
	text-decoration: none;
}

input[type=checkbox], input[type=radio] {
    box-sizing: border-box;
    padding: 0;
    cursor: pointer;
}

/* Inputs, selects, textarea */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	border: none;
	transition: 5000s ease-in-out 0s;
}

/* Tamanho */
.w-100 {
	width: 100%;
}

/* Text */
.text-center {
	text-align: center;
}

/* D-flex */
.d-flex {
	display: flex;
	align-items: center;
	justify-content: center;
}

.justify-center {
	justify-content: center;
}

.justify-between {
	justify-content: space-between;
}

.align-center {
	align-items: center;
}

.d-flex-start{
	display: flex;
	align-items: center;
}

/* Todo conteúdo */
.content-all {
	width: 100%;
	min-height: 100%;
	flex-direction: column;
}

p {
	margin-bottom: 0;
}

/* Menu */
nav {
	width: 250px;
	padding: 20px;
	height: 100%;
	background-color: #fff;
	position: fixed;
	top: 0;
	left: 0;
	overflow-y: auto;
	overflow-x: hidden;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	box-shadow: 1px 1px 15px -10px #004085;
	z-index: 2;
}

nav h1 {
	font-family: 'Roboto condensed', sans-serif;
	text-transform: uppercase;
	font-size: 18px;
	font-style: italic;
	margin: 10px 0px;
	margin-bottom: 20px;
}

nav .menu-item-principal {
	width: 100%;
	height: 40px;
	color: #444;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-weight: 500;
	font-size: 15px;
	padding: 0px 5px;
	transition: all .2s ease-in-out;
	cursor: pointer;
	margin: 5px 0px;
}

.menu-item-principal i {
	width: 36px;
	padding: 0px 10px;
}

.menu-item-principal .fa-chevron-down {
	font-size: 10px;
	display: flex;
	justify-content: flex-end;
}

.menu-item-principal:hover {
	background-color: #f2f8ff;
	color: #FF7115;
	border-radius: 10px;
}

nav ul {
	list-style: none;
	padding-left: 41px;
	display: none;
}

nav ul li {
	text-align: left;
	padding: 10px 0;
	color: #626262;
	text-decoration: none;
	background-color: transparent;
	font-size: 14px;
	transition: color .2s ease-in-out;
}

nav ul li:hover {
	color: #FF7115;
	font-weight: 500;
	text-decoration: none;
}

nav ul a:hover {
	text-decoration: none;
}

.sair {
	margin-top: 10px;
	color: #999 !important;
}

.menu-mob {
	width: 100%;
	height: 50px;
	display: flex;
	align-items: center;
	padding-left: 10px;

}

.menu-mob h1 {
	margin: 0;
	font-weight: 500;
}

.menu-mob i {
	display: none;
}

/* Content-session */
.content-session {
	width: 100%;
	min-height: 100vh;
	padding: 20px 50px;
	padding-bottom: 50px;
	padding-left: 300px;
	background-color: #f1f1f1;
	position: relative;
}

.content-nome {
	width: 100%;
	height: 40px;
	margin-bottom: 20px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.content-nome p,
.content-nome i {
	font-size: 14px;
	margin: 0px 5px;
	color: #777;
}

/* Login */
.content-lexlogin {
	width: 100%;
	min-height: 100vh;
	display: flex;
	justify-content: space-between;
	align-items: center
}

.login {
	width: 45%;
	height: 100vh;
	background-color: #2A2B2E;
	/* border-radius: 10px; */
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
}

.magicLogin{
	width: 55%;
	height: 100vh;
	background-color: #2A2B2E;
	position: relative;
	overflow: hidden;
	/* display: flex; */
	/* justify-content: center; */
	/* align-items: center; */
	box-shadow: 0px 2px 15px rgba(0, 0, 0, .75);
}

.magicLogin img.pyro {
	min-width: 100%;
	min-height: 100%;
	margin-left: -10rem;
}

.magicLogin .overlay{
	top: 0;
	width: 100%;
	height: 100%;
	position: absolute;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
}

.magicLogin .overlay img{
	width: 33%;
	height: auto;
}

.login h1 {
	width: 70%;
	color: #999;
	font-size: 30px;
	text-transform: capitalize;
	font-family: 'Poppins';
	font-weight: 600;
	text-align: start;
	margin-bottom: 2.5px !important;
}

.login p{
	width: 70%;
	text-align: start;
	color: #777;
	font-size: 12px;
	font-family: 'Poppins';
	margin-bottom: 10px;
}

.grupo-input-login {
	width: 70%;
	height: 45px;
	background-color: #212225;
	border-radius: 5px;
	padding: 0px 20px;
	color: #4c4c4c;
	display: flex;
	align-items: center;
	margin: 10px 0px;
	-webkit-box-shadow: 0px 12px 13px -12px #111;
	-moz-box-shadow: 0px 12px 13px -12px #111;
	box-shadow: 0px 12px 13px -12px #111;
	font-family: 'Poppins';
}

.grupo-input-login i {
	margin-right: 10px;
	color: #555;
}

.grupo-input-login input {
	width: 100%;
	border: none;
	background-color: rgba(0, 0, 0, 0);
	font-size: 13px !important;
	padding: 0px 5px;
	outline: none;
	color: #888;
	font-size: 14px;
}

.grupo-input-login input::placeholder {
	font-size: 12px;
}

.grupo-input-submit {
	width: 70%;
	height: 45px;
	border-radius: 5px;
	border: 0px;
	background-color: #FF7115;
	color: #fff;
	margin: 10px 0px;
	font-family: 'Poppins';
	transition: all .2s;
}

.grupo-input-submit:hover{
	background-color: #d85802;
	color: #fff;
}

@media (max-width: 768px) {
	.login {
		width: 60%;
	}
}

/* Formulário */
.content-formulario {
	width: 100%;
	padding: 20px;
	padding-top: 5px;
	border-radius: 0px 0px 10px 10px;
	background-color: #fff;
}

.form-head {
    width: 100%;
    padding: 15px;
    padding-left: 20px;
    border-bottom: 1px solid #f6f6f6;
    border-radius: 10px 10px 0px 0px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.form-head p {
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 500;
	display: inline-block;
}

.form-head i {
	margin-left: 2px;
	padding: 2px 4px 2px 4px;
	color: #fff;
	border-radius: 10%;
	display: inline-block;
}

.form-add {
	min-width: 100px;
    font-size: 12px;
    color: #fff;
    background-color: #FF7115;
    border: 1px solid #FF7115;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
	padding: 5px 15px;
    font-weight: 600;
}

.form-relatorio{
    color: #FF7115;
    border: 1px solid #FF7115;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
	padding: 5px 15px;
	margin-right: 10px;
}

.form-relatorio i{
	color: #FF7115;
}

.form-relatorio p{
    font-size: 12px;
	font-weight: 600;
	text-transform: capitalize;
}

.form-footer {
	width: 100%;
	min-height: 30px;
	border-top: 1px solid #e5e5e5;
	border-radius: 0px 0px 10px 10px;
	background-color: #fff;
}

.descricao-formulario {
	margin-top: 10px;
}

.descricao-formulario p {
	color: #777;
	font-size: 12px;
	font-weight: 400;
	text-align: left;
	text-transform: none;
	color: #999;
}

.descricao-formulario p:nth-child(2) {
	text-decoration: underline;
}

/* Input */
.grupo-input {
	width: 100%;
	padding: 10px 0px;
	position: relative;
}

.grupo-input label {
	margin-left: 2px;
	margin-bottom: 4px;
	font-size: 14px;
}

.grupo-input .grupo-campo-input.disabled {
	background-color: rgb(231, 231, 231);
}

.grupo-input .grupo-campo-input {
	width: 100%;
	min-height: 45px;
	padding: 0px 10px;
	display: flex;
	align-items: center;
}

.grupo-input .grupo-campo-input.hightop{
	align-items: flex-start;
}

.grupo-input .grupo-campo-input.hightop i {
	margin-top: 10px;
}

.grupo-campo-input i {
	width: 30px;
	padding: 0px 10px 0px 2px;
}

.grupo-campo-input input,
select,
textarea {
	width: 100% !important;
	height: 100%;
	color: #444;
	background-color: transparent;
	border: none;
	outline: none;
	font-size: 13px;
	font-family: 'Poppins';
}

.grupo-campo-input input::placeholder,
select::placeholder,
textarea::placeholder{
	color: #999;
}

.select__group {
	font-weight: bold;
	font-style: italic;
}

.grupo-campo-input textarea {
	padding: 10px;
	margin-bottom: 5px;
	resize: vertical;
}


.aviso-formulario p {
	width: 100%;
	margin-top: 10px;
	margin-bottom: 10px;
	font-size: 14px;
	text-align: center;
	transition: 1s ease-in;
	/* color: #004085 !important; */
}

.aviso-formulario-err p{
	font-family: 'Poppins';
	font-weight: 500;
	color: #555 !important;
}

.grupo-submit {
	width: 100%;
	height: 45px;
	margin: 10px 0px;
	cursor: pointer;
}

.grupo-submit button {
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0);
	border: none;
	cursor: pointer;
}

.input-style-cad label {
	color: #777;
	display: flex;
	font-family: 'Poppins';
	font-size: 13px;
	font-weight: 400;
}

.input-style-cad .grupo-campo-input {
	border-radius: 5px;
	border: 1px solid #ddd;
}

.input-style-cad .grupo-campo-input input{
	padding-left: 5px;
}

.input-style-cad .grupo-campo-input i {
	color: #bbb;
	border-right: 1px solid #eee;
}

.input-style-submit-cad {
	width: 100%;
	border: 1px solid #FF7115;
	border-radius: 8px;
	transition: all .2s;
	cursor: pointer;
}

.input-style-submit-cad button {
	font-weight: 500;
	color: #FF7115;
	cursor: pointer;
}

.input-style-submit-cad:hover {
	border: 1px solid #FF7115;
	background-color: #FF7115;
	border-radius: 8px;
}

.input-style-submit-cad:hover button {
	color: #fff;
}

.input-style-des label {
	color: #444;
	display: flex;
	font-family: 'Poppins';
	font-size: 13px;
	font-weight: 600;
}

.input-style-des .grupo-campo-input {
	border-radius: 5px;
	border: 0px;
	background-color: #f1f1f1;
}

.input-style-des .grupo-campo-input i {
	color: #bbb;
}

.modal-body .row{
	margin: 0px;
}

.campo-plus {
	width: 45px;
	height: 45px;
	margin-left: 10px;
	border-radius: 8px;
	background-color: #FF7115;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-size: 20px;
}

/* Tabela */
.table {
	overflow: auto;
	box-shadow: 6px 5px 8px -10px rgba(34,47,62,.5);
	margin-bottom: 0px;
	position: relative;
	background-color: #fff;
}

table {
	min-width: 100%;
	/* background-color: #fff; */
	border-spacing: 0px;
}

table thead {
	width: 100%;
	height: 40px;
}

table thead th {
	min-width: 80px;
	padding: 0px 10px;
	height: 40px;
	color: #777;
	border-right: 1px solid #EEF0FE;
	border-bottom: 2px solid #dee2e6;
	font-size: 14px;
	vertical-align: bottom;
	white-space: nowrap;
}

table thead th i {
	font-size: 12px;
}

table thead tr {
	background-color: #ffffff;
}

table thead th:last-child {
	border-right: 0px;
}

table td,
table th {
	padding: .75rem;
	vertical-align: top;
	border-top: 1px solid #ffffff;
	color: #444;
	font-size: 14px;
	white-space: nowrap;
}

table thead th input{
	border: none;
	margin-left: .5rem;
	width: 100%;
}

tbody tr {
	height: 40px;
}

tbody tr td {
	border-right: 1px solid #EEF0FE;
}

tbody tr td:last-child {
	border-right: 0px;
}

tbody tr:nth-child(odd) {
	background-color: #f9f9f9;
}

tbody tr:nth-child(even) {
	background-color: #fff;
}

.table-black th, .table-black tr,.table-black td{
	border: 1px solid #000;
	padding: .30rem;
}

.td-acoes {
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
}

.td-acoes i{
	margin-right: 5px;
	transition: all .2s;
	color: #555;
}

.td-acoes i:hover{
	color: rgb(255, 146, 73);
}

/* Cards */
.card {
	width: 300px;
	min-height: 200px;
	margin: 10px;
	background-color: #fff;
	border-radius: 10px;
	float: left;
	-webkit-box-shadow: 5px 5px 13px -9px rgba(125, 125, 125, 0.5);
	-moz-box-shadow: 5px 5px 13px -9px rgba(125, 125, 125, 0.5);
	box-shadow: 5px 5px 13px -9px rgba(125, 125, 125, 0.5);
}

.card .card-foto {
	width: 100%;
	height: 140px;
	/* background-image: url(''); */
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	border-radius: 10px 10px 0px 0px;
}

.card-descricao {
	width: 100%;
	min-height: 30%;
	padding: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.card-descricao .nome-automovel {
	font-size: 14px;
	font-weight: 500;
}


.card-descricao .desc p {
	font-size: 13px;
}

.card-descricao .desc a {
	text-decoration: none;
	color: #FF7115;
	font-size: 12px;
	text-transform: uppercase;
	font-weight: 700;
}


.content-individual {
	width: 100%;
	background-color: #fff;
	border-radius: 10px;
	padding: 20px;
}

.galeria {
	width: 100%;
	height: 400px;
	margin-bottom: 10px;
	display: flex;
	justify-content: space-between;
}

.galeria .galeria-row {
	width: 25%;
	height: 100%;
	margin: 0px 10px;
}

.galeria-row .galeria-img {
	width: 100%;
	height: calc(33% - 8px);
	/* background-image: url(''); */
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	margin: 10px 0px;
	border-radius: 5px;
}

.galeria .galeria-bg {
	width: 72%;
	height: calc(100% - 10px);
	/* background-image: url(''); */
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	margin: 10px;
	border-radius: 5px;
}

.content-individual .descricao {
	width: calc(100% -20px);
	margin: 10px;
	display: flex;
	margin-top: 20px;
}

.content-individual .descricao .edit-btn {
	width: 25%;
	height: 40px;
}

.edit-btn button {
	width: 100%;
	height: 100%;
	border: 0px;
	border-radius: 5px;
	padding-left: 20px;
	display: flex;
	align-self: center;
	justify-content: flex-start;
	color: #FF7115;
	border: 1px solid #FF7115;
	background-color: #FFF;
	font-size: 14px;
	transition: all .2s;
	cursor: pointer;
}

.edit-btn button i {
	padding-right: 10px;
}

.edit-btn button:hover {
	color: #fff;
	background-color: #FF7115;
}

.desc-bloco {
	width: 75%;
	margin-left: 30px;
}

.desc-txt {
	margin-bottom: 20px;
	text-align: justify;
}

.desc-detalhes {
	width: 100%;
	height: 40px;
	padding: 0px 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.desc-detalhes:nth-child(odd) {
	background-color: #f1f1f1;
}

.desc-detalhes p:nth-child(1) {
	font-size: 18px;
	font-weight: 700;
	color: #000;
}

.desc-detalhes p:nth-child(2) {
	text-align: right;
}

@media(max-width: 900px) {

	nav {
		width: 100%;
		height: 60px;
		padding: 0px 20px;
		transition: all .2s ease-in-out;
	}

	.menu-mob {
		padding: 0px 10px;
		height: 60px;
		justify-content: space-between;
	}

	.menu-mob i {
		display: block;
	}

	.menu-mob-none {
		display: none;
	}

	.content-session {
		padding: 20px 20px;
	}

	.card {
		width: 100%;
		margin: 10px 0px;
	}

	.galeria-row {
		display: none;
	}

	.galeria .galeria-bg {
		width: 100%;
		margin: 10px 0px;
	}

	.content-individual .descricao {
		flex-direction: column;
		margin: 0px;
	}

	.content-individual .descricao .edit-btn {
		width: 100%;
	}

	.desc-bloco {
		width: 100%;
		margin: 10px 0px;
	}

	.desc-detalhes p:nth-child(2) {
		font-size: 12px;
	}
}

/* Login */
.login-session {
	padding-left: 300px;
	padding-right: 300px;
}


/* Coisas novas */

button.cadbtn {
	width: 100%;
	height: 40px;
	background: none;
	border: 1px solid #FF7115;
	color: #FF7115;
	font-family: 'Poppins';
	transition: all .2s;
}

.cadbtn:hover{
	background-color: #FF7115;
	color: #fff;
	border: none;
}

ul.listC {
	width: 100%;
    max-height: 200px;
    overflow-y: scroll;
    background-color: #fdfdfd;
    list-style: none;
    padding: .5rem;
    position: absolute;
    display: none;
    z-index: 1;
    box-shadow: 2px 3px 15px -10px #555;
}

ul.listC:hover {
	display: block;
}

ul.listC.activate {
	display: block;
}

ul.listC li {
	border-bottom: 1px solid #f1f1f1;
    display: flex;
    align-items: center;
	padding: 5px 10px;
	cursor: pointer;
}

div.gridviewer {
	display: grid;
	grid-gap: 15px;
	grid-template-areas:
        'header header header'
		'card card card';
	grid-template-columns: 1fr 1fr 1fr;
}

div.gridviewer div.viewiten {
	width: 100%;
	background-color: #fff;
	border-radius: 7px;
	overflow: hidden;
	box-shadow: 6px 6px 9px -5px #ccc;
}

div.gridviewer div.viewiten div.imgwrapper {
	width: 100%;
	height: 10rem;
	background-image: url('../imgs/sFoto.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	overflow: hidden;
}

div.gridviewer div.viewiten div.imgwrapper img{
	width: 100%;
	height: auto;
}

div.gridinfo {
	display: grid;
	grid-template-columns: 1fr 1fr;
	cursor: default;
	box-shadow: 0px -5px 9px 0px rgba(0,0,0,0.1);
}

div.gridinfo span {
	line-height: 170%;
	font-size: 14px;
	font-family: 'Poppins';
}

span.orange {
	color: #444;
    font-weight: 600;
	text-transform: capitalize;
	font-size: 15px !important;
	grid-column: 1 / 3;
    padding: 5px 0px;
}

.link-ver{
	color: #17a2b8;
	font-weight: 500;
	text-transform: capitalize;
	font-size: 15px;
	transition: all .2s;
	cursor: pointer;
}

.link-ver:hover{
	color: #FF7115;
}

span.tl {
	text-align: right;
}

span.alb{
	grid-column: 1 / span 2;
	margin-bottom: .5rem;
	color: #777;
}

span.gr{
	color: #777;
}

span.lgm{
	font-size: 1.125rem;
}

div.gridinfo span.badgeman {
	font-weight: 500;
	color: white;
	background-color: #FF7115;
	line-height: 1rem;
	width: fit-content;
    margin-bottom: 10px;
}

.ntl {
	margin: 0;
}

div.ambar{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
    margin-bottom: 20px;
}

div.ambar div.cropper{
	width: 3.5rem;
	height: 3.5rem;
	overflow: hidden;
	border-radius: 6px;
	margin-right: .5rem;
	margin-top: .5rem;
	background-color: #ddd;
	display: flex;
	justify-content: flex-end;
	position: relative;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	cursor: pointer;
}

div.ambar div.cropper:hover div.overpass{
	display: flex;
	opacity: 1;
	position: absolute;
    top: 0;
    right: 0;
    border-radius: 20px;
}

div.ambar div.cropper div.overpass{
	width: 20px;
	height: 20px;
	background: rgba(0, 0, 0, 0.5);
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	transition: 0.3s;
}

div.ambar div.cropper div.overpass i{
	font-size: .7rem;
	color: #fff;
	cursor: pointer;
}

div.ambar div.cropper img{
	width: 100%;
	height: auto;
}

div.itensmodsall{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;	
}

div.itensmodsall div.itemMods{
	padding: .5rem 10px;
    background-color: rgba(0, 123, 255, 0.10);
    border-radius: 6px;
    margin-right: .5rem;
    font-size: 90%;
    color: #007bff;
	font-weight: 500;
	margin-top: .5rem;
}

div.itensmodsall div.itemMods i{
	margin-left: 1rem;
	font-weight: 600;
}

div.infamus{
	width: 100%;
}

div.infamus div.imgcropper{
	width: 100%;
	height: 16rem;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: #ddd;
	border-radius: 8px;
	box-shadow: 0 0 7px 2px rgba(0, 0, 0, 0.15);
}

div.infamus div.imgcropper .img-veiculo{
	width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

div.gridinfoview{
	display: grid;
	grid-template-columns: 1fr 1fr;
}

button.btnora{
	background-color: #FF7115;
	color: #fff;
	border: none;
}

div.cardsItens{
	width: 100%;
	display: flex;
	flex-direction: row;
	margin-top: 1rem;
	flex-wrap: wrap;
}

div.cardsItens div.itensx{
	width: 10rem;
	display: flex;
	flex-direction: column;
	margin-right: 1rem;
	box-shadow: 0 0 8px -1px rgba(0, 0, 0, 0.25);
	border-radius: 8px;
	margin-bottom: 1rem;
}

div.cardsItens div.itensx div.cropper{
	width: 10rem;
	height: 7rem;
	background: #eee;
	overflow: hidden;
	border-radius: 8px;
	position: relative;
}

div.cardsItens div.itensx div.cropper div.killmoto{
	cursor: pointer;
	position: absolute;
	width: 2rem;
	height: 2rem;
	display: flex;
	justify-content: center;
	align-items: center;
	background: white;
	color: #FF7115;
	right: 0;
	font-size: 1.25rem;
	border-bottom-left-radius: 8px;
	opacity: 0;
	transition: 0.3s;
	/* border-left-top-radius: 0px; */
}

div.cardsItens div.itensx div.killnota{
	cursor: pointer;
	width: 2rem;
	height: 2rem;
	display: flex;
	justify-content: center;
	align-items: center;
	background: white;
	color: #FF7115;
	float: right;
	font-size: 1.25rem;
	border-bottom-left-radius: 8px;
}

div.cardsItens div.itensx div.cropper:hover div.killmoto{
	opacity: 1;
}

div.cardsItens div.itensx div.cropper img{
	width: auto;
	height: auto;
	min-width: 100%;
	max-width: 100%;
	min-height: 7rem;
}

div.cardsItens div.itensx p{
	padding: .5rem;
}

div.gridmax{
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
}

div.gridmax span{
	padding: .5rem;
	margin-bottom: .5rem;
}

input.ckck{
	display: none;
}

input.ckck:checked ~ span{
	background-color: #FF7115;
	border: 1px solid #FF7115;
}

input.ckck:checked ~ span::after{
	border-bottom: .225rem solid white;
	border-right: .255rem solid white;
}

span.checkname{
	cursor: pointer;
	display: flex;
	width: 1.75rem;
	height: 1.75rem;
	border-radius: 5px;
	border: 1px solid #ddd;
	transition: 0.3s ease-in-out;
	position: relative;
	align-items: center;
	justify-content: center;
}

span.checkname::after{
	content: '';
	display: block;
	position: absolute;
	width: .52rem;
	height: 1rem;
	transform: rotate(45deg);
	/* top: .1rem; */
}

.card-footer p{
    font-size: 14px;
    color: #FF7115;
}


.barra-mob{
    width: 100%;
    padding: 15px 20px;
    margin-bottom: 10px;
    background-color: #77adff;
    border-radius: 5px;
    color: #fff;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    display: none;
}

.barra-item input, select{
    width: 80%;
    height: 20px;
    border: none;
    color: #444;
    outline: none;
}

.btn-limpar{
    margin: 0px 10px;
    display: flex;
    align-items: center;
    justify-self: center;
    font-size: 14px;
    text-align: center;
    color: #999;
    cursor: pointer;
}

.barra-slide{
    width: 100%;
    background-color: #FF7115;
    padding: 10px 20px;
    margin: 10px 0px;
    border-radius: 5px;
    display: flex;
    align-items: center;
	justify-content: space-between;
	grid-area: header;
}

.barra-slide p:nth-child(1){
    font-size: 15px;
    color: #fff;
    font-weight: 500;
}

.barra-slide p:nth-child(2){
    font-size: 14px;
    color: #999;
}

.barra-slide i{
    font-size: 20px;
    color: #444;
    cursor: pointer;
}

.cards-leads{
    width: 100%;
    display: flex;
	flex-wrap: wrap;
	height: 70vh;
	padding: 5px;
	margin: 20px 0px;
    overflow: auto;
}

.card-item{
    min-width: 33%;
	height: fit-content;   
    background-color: #fff;
    border-radius: 5px;
	margin-bottom: 15px;
	box-shadow: 4px 3px 15px -10px #999;
}

.card-item .card-headerX{
    width: 100%;
    padding: 10px 20px;
    display: flex;
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;
}

.card-headerX .icone-cliente{
    width: 30px;
    height: 30px;
    background-color: #FFE3D0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    margin-right: 10px;
    color: #FF7115;
    font-weight: 500;
    font-size: 14px;
}

.card-headerX .nome-cliente{
    color: #444;
    font-weight: 500;
    font-size: 15px;
}

.card-body{
	padding: 15px 20px;
}

.card-body .auto-modelo p:nth-child(1){
    font-size: 15px;
    color: #444;
    font-weight: 500;
    margin-bottom: 3px;
}

.auto-modelo{
	margin-bottom: 10px;
}

.card-body .auto-modelo p:nth-child(2){
    font-size: 14px;
    color: #999;
}

.card-body .card-caracteristicas{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0px;
		float: left;
}

.card-body .card-timer {
		float: right;
		color: #FF7115;
    font-weight: 500;
    font-size: 14px;
}

.card-caracteristicas .carac-item{
	background-color: rgba(0, 188, 212, .15);
	color: #00BCD4;
	font-weight: 500;
    font-size: 15px;
    padding: 5px 10px;
    border-radius: 5px;
    margin: 5px;
    margin-left: 0px;
    margin-top: 0px;
}

.card-footerX{
    padding: 0px 20px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
}

.card-footerX div{
    width: 30px;
    height: 30px;
    background-color: #FF7115;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 14px;
    margin-right: 10px;
}


.barra-pesquisa{
    width: 100%;
    min-height: 80px;
    background-color: #fff;
    border-radius: 5px;
    padding: 20px;
    display: flex;
}

.barra-item{
    width: 20%;
    margin: 0px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
	border-right: 1px solid #f1f1f1;
	padding-right: 10px;
}

.barra-item p{
    font-size: 15px;
    color: #444;
    font-weight: 500;
    margin-bottom: 2.5px;
}

.card{
    width: 300px;
    min-height: 200px;
    margin: 10px;
    background-color: #fff;
    border-radius: 10px;
    float: left;
    -webkit-box-shadow: 5px 5px 13px -9px rgba(125,125,125,0.5);
    -moz-box-shadow: 5px 5px 13px -9px rgba(125,125,125,0.5);
    box-shadow: 5px 5px 13px -9px rgba(125,125,125,0.5);
}

.spada{
	display: flex;
	overflow-x: auto;
	width: 100%;
}

.spax{
	min-width: 22rem;
	margin-right: 10px;
}

div.viewer{
	width: 100%;
	height: 10rem;
	background-color: #ddd;
	overflow: hidden;
	border-radius: 5px;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	box-shadow: inset 0px -4px 10px -10px #000;
}

.spax div.viewer img{
	width: 100%;
	height: auto;
}

.mininav{
	display: flex;
	padding: 15px 20px;
    background-color: #fbfbfb;
    border-radius: 5px;
    box-shadow: 3px 3px 10px -10px #999;
}

.pure{
	margin-left: 1rem !important;
	width: auto !important;
	color: #FF7115;
	border: 1px solid #FF7115;
	border-radius: 4px;
	height: auto !important;
	padding-left: 3px;
	/* padding: 2px 4px 2px 4px; */
}

.p-modal{
	margin-bottom: 10px;
	color: #555;
}

h5{
	font-size: 16px !important;
	color: #333;
}

.avisoerr{
	color: #ff7707 !important;
	font-weight: 300 !important;
}

.badge{
	padding: 5px 10px;
}

.box-conteudo{
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	padding: 15px 20px;
}

.conteudo-info{
	width: 30%;
	margin-right: 20px;
	margin-bottom: 15px;
}

.conteudo-info-2{
	width:50%;
	margin-right: 20px;
	margin-bottom: 15px;
}

.conteudo-info p:nth-child(1), .conteudo-info-2 p:nth-child(1){
	font-weight: 600;
	font-size: 13px;
	color: #555;
}

.conteudo-info p:nth-child(2), .conteudo-info-2 p:nth-child(2){
	font-size: 15px;
	color: #777;
	min-height: 12px;
}

.header-relatorio{
	font-size: 18px;
	padding: 10px 20px;
    border-radius: 5px;
    background: #f1f1f1;
    color: #555;
}


.subtitle-form{
	text-transform: uppercase;	
	font-size: 12px;
	margin: 10px 0px;
	color: #888;
	font-family: 'Poppins';
}

#estilo{
	display: none;
}

.relatorio-head{
	width: 100%;
	padding: 15px 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #fff;
	border-radius: 5px 5px 0px 0px;
	position: relative;
	cursor: default;
}

.relatorio-head .title{
	text-transform: uppercase; 
    font-size: 14px;
    font-weight: 500;
    display: inline-block;
}

.relatorio-head .campo-data{
	/* border: 1px solid #FF7115; */
	border-radius: 5px;
	padding: 1.5px 5px;
}

.relatorio-head .campo-data input[type=month]{
	border: none;
	padding: 0px 10px;
	font-size: 14px;
	outline:none;
	color: #999;
}

.relatorio-body{
	width: 100%;
	min-height: calc(100vh - 200px);
	background-color: #fff;
	padding: 0px 20px;
}

.relatorio-body .list-item{
	width: 100%;
	height: 40px;
	display: flex;
	align-items: center;
	padding: 5px 20px;
}

.relatorio-body .list-item .data{
	padding-right: 20px;
	border-right: 1px solid #ddd;
	font-weight: 500;
}
.relatorio-body .list-item .nome{
	padding-left: 10px;
	color: #626262;
	text-transform: capitalize;
}

.relatorio-body .list-item:nth-child(odd) {
	background: #f1f1f1;
}

.relatorio-body #nenhum{
	background-color: #f1f1f1;
	color: #999;
	display: none;
	align-items: center;
}

.relatorio-footer{
	width: 100%;
	height: 20px;
	background-color: #fff;
	border-radius: 0px 0px 5px 5px;
}

.titulo{
	color: #777;
	font-size: 14px;
	margin-bottom: 10px;
}

#data_retorno{
	display: none;
}

.conteudo-info-modelos{
	width: 100%;
}

.conteudo-info-modelos p{
	padding: 10px 15px;
    background: #f1f1f1;
    color: #212529;
    font-size: 13px;
    font-weight: 600;
    border-radius: 5px;
}

.conteudo-info-modelos li{
	padding: 5px 30px;
    font-size: 13px;
    color: #495057;
}

.relatorio-info{
	width: 100%;
	padding: 20px;
	background-color: #fff;
	border-radius: 5px;
	font-family:'Poppins';
}


.relatorio-info .head{
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.relatorio-info .head p{
	font-weight: 600;
	font-size: 14px;
	font-family: 'Poppins';
}

.relatorio-info .head div input{
	border: 0px;
	font-size: 13px;
	outline: none;
	font-family: 'Poppins';
}

.relatorio-info .body{
	width: 100%;
	padding: 20px 0px;
	display: flex;
	justify-content: space-between;
}

.relatorio-info .body .indicadores{
	display: flex;
	align-items: center;
	min-width: 130px;
}

.indicadores i{
	font-size: 25px;
	margin-right: 12.5px;
}

.indicadores p:nth-child(1){
	font-size: 13px;
	font-weight: 600;
	color: #333333;
	font-family:'Poppins';
}

.indicadores p:nth-child(2){
	font-size: 13px;
	color: #888;
	font-family:'Poppins';
	display: flex;
	align-items: center;
}

.indicadores p:nth-child(2) i{
	font-size: 10px;
	margin-left: 5px;
}

.positivo{
	color: #17AD1D;
}

.negativo{
	color: #FF070D;
}

#receber{
	color: #00ffea;
}

#pagar{
	color: #FF070D;
}


#recebido{
	color: #17AD1D;
}

#pago{
	color: #ff7707;
}

#lucro{
	color: #8ed0ff;
}

#previsao{
	color: #ffcc12;
}

.relatorio-info .body .btn-add{
	font-size: 12px;
	font-family:'Poppins';
	outline: none;
}

.relatorio-info .body .btn-add:hover{
	-webkit-box-shadow: 0px 5px 22px -10px rgba(153,153,153,0.5);
	-moz-box-shadow: 0px 5px 22px -10px rgba(153,153,153,0.5);
	box-shadow: 0px 5px 22px -10px rgba(153,153,153,0.5);
}

.btn-add{
	height: 40px;
	padding: 0px 20px;
	border: none;
	background-color: #FF7115;
	color: #fff;
	font-weight: 600;
	border-radius: 5px;
	font-size: 14px;
	font-family: 'Poppins';
}

.btn-add i{
	margin-left: 10px;
}

.barra-porcentagem{
	width: 100%;
	height: 5px;
	margin: 20px 0px;
}

.barra-porcentagem .barra-cheia{
	width: 100%;
	height: 100%;
	background-color: #f1f1f1;
	position: relative;
}


.barra-cheia #bar-receber{
	height: 100%;
	background-color: #17AD1D;
	position: absolute;
}

.barra-cheia #bar-pagar{
	height: 100%;
	background-color: #ff7707;
	position: absolute;
	left: 0;
}

.relatorio-titulo{
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	margin: 20px 0px;
	padding-left: 15px;
}

.relatorio-titulo p{
	text-transform: uppercase;
	color: #999;
	font-size: 14px;
}

.relatorio-titulo div{
	padding: 10px 15px;
	background-color: #fff;
	border-radius: 5px;
	font-size: 14px;
	margin-top: 10px;
}

.bg-laranja{
	background-color: #FF7115;
	border-color: #FF7115;
}

.bg-laranja:hover{
	background-color: #f7690b;
	border-color: #f7690b;
}

#dataPag{
	display: none;
}

#grupo-pago{
	display: none;
}

#dataPag2{
	display: none;
}

#grupo-pago2{
	display: none;
}

.tag-status{
	width: 20px;
	height: 5px;
	border-radius: 5px;
}

.tag-p{
	background-color: #ff7707;
}

.tag-r{
	background-color: #17AD1D;
}

.tag-ap{
	background-color: #ff0707;
}

.tag-ar{
	background-color: #00ffea;
}

.tag-R{
	background-color: #17AD1D;
}

.tag-D{
	background-color: #ff0707;
}

.aviso p{
	font-size: 14px;
	word-spacing: 150%;
	color: #333;
}

.aviso img{
	width: 120px;
	margin-left: 10px;
}

.all-contas{
	width: 100%;
	margin: 10px 0px;
	display: flex;
	flex-wrap: wrap;
}

.contas{
	width: 300px;
	padding: 15px;
	background-color: #fff;
	border-radius: 5px;
	margin-right: 10px;
	margin-bottom: 10px;
	cursor: pointer;
}

.contas .header{
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.contas .header .bolinha{
	width: 30px;
	height: 30px;
	color: #fff;
	background-color: #ff7707;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 10px;
	text-transform: uppercase;
	font-weight: 600;
}

.contas .header p:nth-child(2){
	font-size: 14px;
	color: #333;
	font-weight: 600;
}

.contas .header i{
	font-size: 14px;
	color: #777;
}


.categoria{
	width: 100%;
	padding: 0px 15px;
	padding-right: 20px;
	background-color: #fff;
	margin: 10px 0px;
	border-radius: 5px;
}

.categoria-head{
	height: 55px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	position: relative;
}

.categoria-body{
	padding-bottom: 15px;
	display: none;
}

.categoria-body .cat-sub-item{
	width: calc(100% - 30px);
	height: 40px;
    padding: 0px 15px;
    display: flex;
	align-items: center;
	justify-content: space-between;
    border-left: 1px solid #ccc;
	margin: 5px 0px;
	margin-left: 15px;
	cursor: pointer;
}

.cat-sub-item:hover{
	font-weight: 600;
	border-left: 1px solid #ff7707;
}

.categoria .bolinha{
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	color: #fff;
	font-weight: 600;
	font-size: 14px;
	text-transform: uppercase;
	margin-right: 10px;
	transition: all .1s;
}

.categoria:hover{
	box-shadow: 4px 4px 10px -10px black;
}

.categoria .p-cat{
	font-size: 14px;
	font-family: 'Poppins';
	color: #333;
}

.categoria i{
	color: #999;
}

.toggleOptions{
	width: 200px;
	padding: 10px;
	position: absolute;
	right: 0;
	top: 40px;
	background-color: #e5e5e5;
	z-index: 1;
	border-radius: 5px;
	display: none;
	-webkit-box-shadow: 0px 7px 17px -13px rgba(0,0,0,0.75);
	-moz-box-shadow: 0px 7px 17px -13px rgba(0,0,0,0.75);
	box-shadow: 0px 7px 17px -13px rgba(0,0,0,0.75);
}

.toggleOptions i{
	font-size: 13px;
	margin-right: 10px;
}

.toggleOptions p{
	font-size: 14px;
	padding: 5px 0px;
	color: #555;
}

.toggleOptions p:hover i{
	color: #c55901;
}

.cat-head-calc{
	width: calc(100% - 20px);
}

.cont-head-calc{
	justify-content: flex-start;
	width: calc(100% - 50px);
}


/* Checked */

.switch {
	position: relative;
	display: inline-block;
	width: 40px;
	height: 20px;
	margin: 0px;
  }
  
.switch input { 
opacity: 0;
width: 0;
height: 0;
}

.slider {
position: absolute;
cursor: pointer;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-color: #ccc;
-webkit-transition: .4s;
transition: .4s;
}

.slider:before {
position: absolute;
content: "";
height: 16px;
width: 16px;
left: 2px;
bottom: 2px;
background-color: white;
-webkit-transition: .4s;
transition: .4s;
}

input:checked + .slider {
background-color: #2196F3;
}

input:focus + .slider {
box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
-webkit-transform: translateX(20px);
-ms-transform: translateX(20px);
transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
border-radius: 34px;
}

.slider.round:before {
border-radius: 50%;
}

/* Item-op */

.item-opcao{
	padding: 0px 20px;
	background-color: #fff;
	border-radius: 5px;
}

.item-opcao .head{
	width: 100%;
	height: 50px;
	margin: 10px 0px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	cursor: pointer;
}

.gray{
	color: #333;
}

.pergunta-p{
	font-weight: 500;
	color: #333;
}

.p-head{
	width: 90%;
	height: 100%;
	display: flex;
	align-items: center;
}

.item-opcao .item-body{
	padding-bottom: 15px;
	display: none;
}

.item-opcao .item-body .alt-item{
	width: calc(100% - 30px);
	height: 40px;
    padding: 0px 15px;
    display: flex;
	align-items: center;
	justify-content: space-between;
    border-left: 1px solid #ccc;
	margin: 5px 0px;
	margin-left: 15px;
}

.alt-item{
	width: calc(100% - 30px);
	height: 40px;
    padding: 0px 15px;
    display: flex;
	align-items: center;
	justify-content: space-between;
    border-left: 1px solid #ccc;
	margin: 5px 0px;
	margin-left: 15px;
}

.pointer{
	cursor: pointer;
}

/* questionario */

.questionario{
	background-color: #fff;
	padding: 20px;
	border-radius: 5px;
}

.questionario .head{
	font-weight: 500;
	font-family: 'Poppins';
	padding-bottom: 20px;
}

.questionario .perguntas{
	padding: 0px 10px;
}

.questionario .questao{
	padding-bottom: 20px;
}

.questao .enunciado{
	display: flex;
	align-items: center;
}

.enunciado p:nth-child(1){
	margin-right: 5px;
	font-weight: 500;
}

.questao .resposta{
	width: 100%;
	height: 30px;
	border-bottom: 1px solid #b5b5b5;
	margin-top: 5px;
}

.questao .opcao{
	display: flex;
	flex-direction: column;
	padding: 0px 5px;
}

.opcao .alternativa{
	display: flex;
	align-items: center;
	padding: 5px 0px;
	position: relative;
	z-index: 1;
}

.alternativa label{
	margin-bottom: 0px;
}

.resposta input{
	width: 100%;
	height: 100%;
	border: 0px;
	outline: none;
	padding-left: 5px;
	color: #333;
}

.btn-prox-volt{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 0px 0px;
}

.btn-prox-volt button{
	padding: 8px 25px;
	border-radius: 5px;
	border: none;
	background-color: #FF7115;
	color: #fff;
	font-weight: 400;
	font-size: 14px;
	outline: none;
}

.btn-prox-volt button i{
	margin-left: 5px;
}

.btn-prox-volt button:hover{
	background-color: #fa6400;
	color: #fff;
}

.btn-prox-volt button.btn-voltar{
	background-color: #fff;
	color: #959595;
}

/*  */
.alternativa input[type=radio]{
	position: absolute;
	visibility: hidden;
}
  
.alternativa label{
	display: flex;
	align-items: center;
	position: relative;
	font-weight: 300;
	z-index: 9;
	padding-left: 5px;
	font-size: 14px;
	cursor: pointer;
	-webkit-transition: all 0.25s linear;
}

.alternativa .check{
	display: block;
	border: 2px solid #AAAAAA;
	border-radius: 100%;
	height: 16px;
	width: 16px;
	z-index: 5;
	transition: border .25s linear;
	-webkit-transition: border .25s linear;
	margin-right: 5px;
}
  
.alternativa .check::before {
	display: block;
	position: absolute;
	content: '';
	border-radius: 100%;
	height: 8px;
	width: 8px;
	top: 6.5px;
    left: 9px;
	margin: auto;
	transition: background 0.25s linear;
	-webkit-transition: background 0.25s linear;
}
  
.alternativa input[type=radio]:checked ~ label .check {
	border: 2px solid #FF7115;
  }
  
.alternativa input[type=radio]:checked ~ label .check::before{
	background: #FF7115;
}

.perguntas{
	display: none;
	min-height: 700px;
    flex-direction: column;
    justify-content: space-between;
}

.no-min-height {
	min-height: 0 !important;
}

.box-obs{
	height: 100px;
	border: 1px solid #d5d5d5;
	border-radius: 5px;
	margin-bottom: 20px;
	margin-top: 10px;
	padding: 10px 15px;
}

.resposta-obs{
	font-size: 14px;
}

.obrigatorio{
	display: flex;
	align-items: center;
	padding-left: 10px;
}

.obrigatorio label{
	margin: 0px;
	margin-left: 10px;
}

.default{
	cursor: default !important;
}

.viewPessoa{
	padding: 10px 0px;
	display: flex;
	align-items: flex-end;
}

.viewPessoa button{
	height: 45px;
	padding: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 5px;
	border: none;
	outline: none;
}

#pessoa{
	display: none;
}

#aviso{
	font-size: 14px;
	color: #17AD1D;
	font-weight: 500;
	font-family: 'Poppins';
	display: none;
}

#avisoLead{
	font-size: 14px;
	font-weight: 500;
	font-family: 'Poppins';
	display: none;
}


.cabecalho-head{
	background-color: #fff;
	padding: 20px;
	border-radius: 5px;
	margin-bottom: 10px;
}

.cabecalho-head .head{
	font-weight: 500;
	font-family: 'Poppins';
}

.btn-prox-volt .btn-descartar{
	background-color: #bbb;
}

.btn-prox-volt .btn-descartar:hover{
	background-color: #757575;
}

/* Progress */
.andamento_compras{
	margin: 10px 0px;
	background-color: #fff;
	padding: 20px;
	border-radius: 5px;
}

.andamento_compras .head{
	display: flex;
	font-weight: 500;
	
}

.andamento_compras .head p{
	font-family: 'Poppins';
	font-size: 14px;
	color: #555;
	display: flex;
	align-items: center;
}

.space-p{
	width: 10px;
	height: 2px;
	background-color: #555;
}

/* 
  Código retirado do site https://bootsnipp.com/
  Creditos totais para o usuário alilishan
  Link do código:
  https://bootsnipp.com/snippets/featured/form-process-steps
*/

.bs-wizard {
	margin-top: 30px;
	border-bottom: solid 1px #e0e0e0; 
	padding: 0 0 10px 0;
}

.bs-wizard > .bs-wizard-step {
	padding: 0; 
	position: relative;
	width: 20%;
}

.bs-wizard > .bs-wizard-step + .bs-wizard-step {}

.bs-wizard > .bs-wizard-step .bs-wizard-stepnum {
	color: #595959; 
	font-size: 14px;
	margin-bottom: 5px;
}

.bs-wizard > .bs-wizard-step .bs-wizard-info {
	color: #999; 
	font-size: 12px;
	font-family: 'Poppins';
}

.bs-wizard > .bs-wizard-step > .bs-wizard-dot {
	position: absolute;
	width: 30px;
	height: 30px;
	display: block;
	background: #ffceae;
	top: 45px;
	left: 50%;
	margin-top: -15px;
	margin-left: -15px;
	border-radius: 50%;
} 

.bs-wizard > .bs-wizard-step > .bs-wizard-dot:after {
	content: ' '; 
	width: 14px; 
	height: 14px; 
	background: #ff9c5b; 
	border-radius: 50px; 
	position: absolute; 
	top: 8px; 
	left: 8px; 
} 

.bs-wizard > .bs-wizard-step > .progress {
	position: relative;
	border-radius: 0px; 
	height: 8px;
	box-shadow: none;
	margin: 20px 0;
}

.bs-wizard > .bs-wizard-step > .progress > .progress-bar {
	width:0px; box-shadow: none; background: #ffc59f;
}

.bs-wizard > .bs-wizard-step.complete > .progress > .progress-bar {
	width:100%;
}

.bs-wizard > .bs-wizard-step.active > .progress > .progress-bar {
	width:50%;
}

.bs-wizard > .bs-wizard-step:first-child.active > .progress > .progress-bar {
	width:0%;
}
.bs-wizard > .bs-wizard-step:last-child.active > .progress > .progress-bar {
	width: 100%;
}

.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot {
	background-color: #f5f5f5;
}

.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot:after {
	opacity: 0;
}

.bs-wizard > .bs-wizard-step:first-child  > .progress {
	left: 50%; 
	width: 50%;
}

.bs-wizard > .bs-wizard-step:last-child  > .progress {
	width: 50%;
}

.bs-wizard > .bs-wizard-step.disabled a.bs-wizard-dot{ 
	pointer-events: none;
}

/*END Form Wizard*/

.legenda{
	padding-bottom: 20px;
	font-size: 12px;
	color: #959595;
}

button{
	outline: none;
}

.card-venda{
	width: 100%;
    min-height: 380px;
	border-radius: 10px;
	margin: 10px 0px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    border: 1px solid #f1f1f1;
	-webkit-box-shadow: 0px 8px 19px -10px rgba(0,0,0,0.3);
	-moz-box-shadow: 0px 8px 19px -10px rgba(0,0,0,0.3);
	box-shadow: 0px 8px 19px -10px rgba(0,0,0,0.3);
}

.card-venda .titulo{
	width: 100%;
	text-align: center;
	font-size: 16px;
	font-weight: 500;
	color: #363636;
}

.card-venda img{
	height: 150px;
	margin: 20px 0px;
}

.card-venda .texto{
	color: #767676;
	font-size: 14px;
}

.valorFipe{
	color: #777;
    display: flex;
    font-family: 'Poppins';
    font-size: 13px;
	font-weight: 400;
}

.redefinir-senha p{
	font-family: 'Poppins';
	color: #828282;
	transition: all .2s;
	cursor: pointer;
	font-size: 14px;
}

.redefinir-senha i{
	color: #bbb;
}

.redefinir-senha:hover p, .redefinir-senha:hover i{
	color: #ff7707;
}

.opcoes{
	width: 100%;
}

.opcoes .alternativa{
	margin: 8px 0px;
}

.list-itens{
	padding: 10px 10px;
	max-height: 50vh;
	overflow-y: auto;
}

.check-itens{
	padding: 5px 0px;
	display: flex;
	align-items: center;
}

.check-itens label{
	padding-left: 10px;
	margin: 0px;
	display: flex;
	align-items: center;
	color: #555;
	font-size: 14.5px;
	cursor: pointer;
}

.placa{
	color: #ff7707;
	padding-left: 8px;
	font-weight: 600;
}

.bar-box{
	width: fit-content;
	display: flex;
	flex-direction: column;
}

.bar-box .bar{
	max-width: 20px;
	height: 10px;
	background-color: #444;
}

.bar-box p{
	font-size: 12px;
}

#valor-mensal{
    color: #555;
	font-size: 14px;
	margin-bottom: 10px;
}

#valor-mensal i{
	margin-right: 5px;
}

.txt-info{
	margin: 15px 0px;
	margin-top: 10px;
}

.txt-info p:nth-child(1){
	font-weight: 500;
	color: #444;
}

.txt-info p:nth-child(2){
	color: #999;
}

.btn-env{
	width: 100%;
	height: 45px;
}

.arrows{
	color: #777;
}


.paginacao{
	border-radius: 5px;
	color: #555;
}

.pag-select{
	font-weight: 500;
	background-color: #ccc;
	color: #555;
}

button{
	outline: 0;
}

.header-print{
	padding: 5px 10px;
	background-color: #f7f7f7;
	color: #555;
	border-radius: 5px;
	cursor: pointer;
	margin-left: 10px;
}

.header-print:hover{
	background-color: #e5e5e5;
}

.span-orange{
	color: #FF7115;
	font-weight: 600;
	font-size: 14px;
}

.icone{
	display: flex;
	padding: 3px;
}

.icone i{
	font-size: 14px;
    padding: 0px 5px;
	color: #444;
	cursor: pointer;
}

.resposta{
	margin: 8px 0px;
    padding: 0px 20px;
}

.title{
	font-size: 15px;
	font-weight: 600;
	color: #444;
}

.text{
	font-size: 15px;
	color: #626262;
	padding-top: 3px;
}

.subtitle{
	font-size: 15px;
	color: #636c74;
	font-weight: 500 !important;
	padding-top: 3px;
}

.modal__img{
	width: 100%;
	height: 300px;
	padding: 10px 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #f1f1f1;
	border-radius: 3px;
	margin-bottom: 10px;
}

.modal__img img{
	max-height: 280px;
}

.modal__img i{
	font-size: 30px;
	color: #bbb;
	cursor: pointer;
}

.modal__img i:hover{
	color: #555;
}

.icone{
	font-size: 0.8rem;
	color: #777;
	cursor: pointer;
}

#capa--active{
	border: 2px solid #ff7115;
}

.cancelado{
	text-decoration: line-through;
	color: #999;
}

.versao{
	width: 100%;
	padding: 10px 5%;
	text-align: end;
	color: #999;
	background: #f1f1f1;
	font-weight: 500;
	font-size: 14px;
}

.label{
	margin: 10px 0px;
    width: fit-content;
    padding: 2.5px 15px;
    background: #ff7115;
    border-radius: 5px;
    font-weight: 500;
    color: #fff;
	font-size: 14px;
	/* box-shadow: 11px 10px 20px -10px rgba(0,0,0,0.5); */
}

.btn-acesso{
	display: inline-block;
    font-weight: 400;
	color: #fff !important;
	font-weight: 500;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    padding: .375rem .75rem;
    font-size: 1rem;
	line-height: 1.5;
	border: none;
	border-radius: .25rem;
}

button:focus{
	outline: none;
}

.infobox{
	position: absolute;
    right: 20px;
    bottom: -25px;
    font-size: 12px;
    background: rgb(255 113 21 / 30%);
    padding: 10px;
    color: #ffffff;
	border-radius: 3px;
	display: none;
	transition: all .5s;
}

.relatorio-head:hover .infobox{
	display: block;
}

.break--word{
	word-break: break-word;
}

.overlay{
	width: 100%;
    height: 100vh;
    position: fixed;
    background-color: rgba(0,0,0,0.4);
    z-index: 1100;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal__loading{
    width: 300px;
	padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal__loading img{
	width: 100%;
}

.modal__loading p{
	font-size: 20px;
    color: #333;
    font-weight: 500;
}


.pendente{
	color: #ff7707;
	font-weight: 500;
}

.notificacao{
	width: 100%;
	margin: 10px 0px;
	padding: 15px;
	background-color: #fff;
	border-radius: 5px;
}

.notificacao__header{
	width: 100%;
	display: flex;
	align-items: center;
}

.notificacao__icone{
	width: 30px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #FFF1E8;
	color: #f7690b;
	border-radius: 50%;
	margin-right: 10px;
}

.notificacao__header p{
	color: #333;
	font-weight: 500;
}

.notificacao__buttons{
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	margin: 10px 0px;
}

.notificacao__btn{
	min-width: 60px;
	padding: 3px 15px;
	border-radius: 5px;
	font-size: 14px;
	border: none;
	border: 1px solid #fdaf7b;
	background-color: transparent;
	color: #fdaf7b;
	margin: 2.5px 0px;
	margin-right: 5px;
	font-family: 'Poppins';

}

.notificacao__btn--active{
	background-color: #ff7115;
	color: #fff;
	border: 1px solid #ff7115;
	font-weight: 500;
}

.notificacao__body{
	max-height: 300px;
	overflow-y: auto;
}

.notificacao__und{
	margin-top: 10px;
	position: relative;
	width: 100%;
	display: flex;
	padding: 10px 0px;
	border-bottom: 1px solid #EEF0FE;
    padding-right: 10px;
}

.notificacao__data{
	width: 60px;
	height: 60px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	line-height: 20px;
	box-shadow: 2px 2px 4px #EAEAEA;
	border-radius: 5px;
	margin-right: 10px;
}

.notificacao__data p:nth-child(1){
	font-size: 24px;
	font-weight: 500;
	color: #002C6F;
}

.notificacao__data p:nth-child(2){
	font-size: 14px;
	color: #77ADFF;
	font-family: 'Poppins';
	
    text-align: center;
}


.notificacao__info{
	width: calc(100% - 70px);
	display: flex;
	justify-content: space-between;
	padding-right: 10px;
}

.notificacao__descricao{
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-right: 10px;
}

.notificacao__descricao p:nth-child(1){
	font-weight: 500;
	color: #333;
	font-family: 'Poppins';
}

.notificacao__descricao p:nth-child(2){
	font-weight: 500;
	font-size: 14px;
	font-family: 'Poppins';
}

.notificacao__descricao--pagar{
	color: #ff7115;
}

.notificacao__descricao--receber{
	color: #77ADFF;
}


.notificacao__valor{
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.notificacao__valor p{
	height: 20px;
	font-size: 14px;
	font-weight: 500;
	color: #555555;
}

.notificacao__lida {
	position: absolute;
	top: -5px;
	right: 5px;
	border-radius: 5px;
	background-color: #ff7315c5;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 4px;
	margin-left: 15px;
	cursor: pointer;
	border: 0;
}
.notificacao__lida:hover {
	background-color: #ff7315;
}

.notificacao__lida i {
	color: #ffffff;
	font-size: 11px;
	font-weight: bold;
}

.bells{
	position: relative;
}

.bells-status{
	width: 15px;
	height: 15px;
	border-radius: 50%;
	background-color: #FF7115;
	color: #fff;
	font-size: 12px;
	font-weight: 500;
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	top: -8px;
	left: -3px;
}

.texto{
	margin-bottom: 10px;
	padding: 5px 15px;
}

.texto p:nth-child(1){
	font-size: 14px;
	color: #333;
	font-weight: 600;
}

.texto p:nth-child(2){
	font-size: 16px;
	color: #555;
}

.nav-slide {
	display: none;
}

@media(max-width: 900px){

    nav{
        width: 100%;
        height: 60px;
        padding: 0px 20px;
        transition: all .2s ease-in-out;
    }

    .menu-mob{
        padding: 0px 10px;
        height: 60px;
        justify-content: space-between;
    }

    .menu-mob i{
        display: block;
    }

    .menu-mob-none{
        display: none;
    }

    .content-session{
		padding-left: 20px;
	}

    .card{
        width: 100%;
        margin: 10px 0px;
    }

    .galeria-row{
        display: none;
    }

    .galeria .galeria-bg{
        width: 100%;
        margin: 10px 0px;
    }

    .content-individual .descricao{
        flex-direction: column;
        margin: 0px;
    }

    .content-individual .descricao .edit-btn{
        width: 100%;
    }

    .desc-bloco{
        width: 100%;
        margin: 10px 0px;
    }

    .desc-detalhes p:nth-child(2){
        font-size: 12px;
    }


    /* Leads */
    .barra-pesquisa{
      flex-direction: column;
    }

    .barra-item{
        width: 100%;
        border: 0px;
        border-bottom: 1px solid #f1f1f1;
        padding: 10px 0px;
        margin: 0px;
    }

    .btn-limpar{
        margin: 0px;
        padding-top: 20px;
    }

    .barra-mob{
        display: flex;
    }

    .cards-leads .card-item{
        min-width: 100%;
        margin-right: 0px;
    }

    #barra{
        display: none;
	}
	
	.box-conteudo .conteudo-info, .box-conteudo .conteudo-info-2{
		width: 100%;
	}

	.perguntas{
		min-height: calc(100vh - 100px);
	}

	.viewPessoa{
		padding: 10px 15px;
		width: 100%;
	}

	.viewPessoa button{
		width: 100%;
	}

	.btn-prox-volt button{
		padding: 8px 15px;
	}
}


@media only screen and (max-width: 668px) {
	div.gridviewer {
		grid-template-areas: 'header header' 'card card';
		grid-template-columns: 1fr 1fr !important;
	}

	.magicLogin{
		display: none;
	}

	.login{
		width: 100%;
	}

	.relatorio-info .body{
		flex-direction: column;
		padding: 0;
	}

	.relatorio-info .body div.d-flex{
		flex-direction: column;
		align-items: flex-start;
	}

	.relatorio-info .body .indicadores{
		padding: 7.5px 5px;
	}

	.relatorio-info .body .btn-add{
		width: fit-content;
		margin-top: 10px;
		padding: 10px 20px;
	}

	.relatorio-info .barra-porcentagem{
		padding: 0px;
	}

	.bs-wizard {
		margin-top: 10px;
	}

	.bs-wizard>.bs-wizard-step .bs-wizard-info {
		font-size: 8px;
	}

	.bs-wizard > .bs-wizard-step .bs-wizard-stepnum {
		font-size: 12px;
	}

	.andamento_compras .head p{
		font-size: 12px;
	}

	.line-separa{
		border-bottom: 1px solid #e9ecef;
		padding-bottom: 15px;
	}
}


@media only screen and (max-width: 425px) {
	.login {
		width: 100%;
		/* height: 100%; */
	}

	div.gridviewer {
		grid-template-areas: 'header' 'card';
		grid-template-columns: 1fr !important;
	}

	.grupo-input-login, .login p, .login h1, .grupo-input-submit{
		width: 85%;
	}

	.form-relatorio p{
		display: none;
	}
}

@media print {

	.box-conteudo .conteudo-info, .box-conteudo .conteudo-info-2 {
		width: 22%;
	}
	
  nav{
    display: none;
	}

	.content-nome {
		display: none;
	}
	
	.content-session{
		background-color: #fff;
	}

}

@media print {
	

	@page {
		size: auto !important;
    margin: 10mm;
  }

	.content-session, .table {
		position: static !important;
	}
	.no-print {
		display: none;
	}
	
	.print-table {
		top: 0;
		left: 0;
		position: absolute;
		width: 100vw;
		height: 100vh;
		display: block !important;
		background-color: #FFF;
	}
	.print-table p {
		display: block !important;
	}
	.gestao tr td, th {
		padding: 10px 6px !important;
		min-width: 0 !important;
		font-size: 7px !important;
	}
	.mov tr td, th {
		min-width: 0 !important;
		font-size: 8px !important;
	}
	.planosConta tr td, th {
		padding: 10px 6px !important;
		min-width: 0 !important;
		font-size: 10px !important;
	}

	.form-footer {
		display: none;
	}

	.d-flex {
		display: block;
	}
}

@media print and (orientation: landscape) {
	.container{
		position: absolute;
		width: 100%;
		height: 100%;
		margin-top: 0px !important;
		font-size: 13px;
	}
	.gestao tr td, th {
		min-width: 80px !important;
		font-size: 10px !important;
	}
	.mov tr td, th {
		min-width: 80px !important;
		font-size: 11px !important;
	}
	.termo {
		font-size: 8px;
	}
}
